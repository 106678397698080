<template>
  <ag-grid-vue
    v-bind="$attrs"
    :key="gridKey"
    :props="$attrs"
    class="elv-grid-theme-quartz-table"
    style="width: 100%; height: 100%"
    :rowData="rowData"
    :defaultColDef="props.defaultColDef"
    :grid-options="gridOptions"
    :loadThemeGoogleFonts="true"
    :autoGroupColumnDef="props.autoGroupColumnDef"
    :sideBar="sideBar"
    :floatingFilter="true"
    :initialState="initialState"
    @grid-ready="onGridReady"
    @cellClicked="onCellClicked"
    @state-updated="onStateUpdated"
    @grid-pre-destroyed="onGridPreDestroyed"
  />
</template>

<script setup lang="ts">
import {
  ModuleRegistry,
  type ColDef,
  GridApi,
  GridState,
  SideBarDef,
  DetailGridInfo,
  CellClassParams,
  CellClickedEvent,
  StateUpdatedEvent,
  HeaderClassParams
} from '@ag-grid-community/core'
import { AgGridVue } from '@ag-grid-community/vue3'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { themeQuartz } from '@ag-grid-community/theming'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { AG_GRID_LOCALE_CN, AG_GRID_LOCALE_EN } from '@ag-grid-community/locale'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'

import { isEmpty, uniqueId } from 'lodash-es'
import noDataImg from '@/assets/img/noData.png'
import { TableGridType } from '#/GlobalConfigTypes'
import { useGlobalStore } from '@/stores/modules/global/index'
import GroupCountCellRenderer from './Cell/GroupCountCellRenderer.vue'

defineOptions({
  inheritAttrs: false
})

const elvThemeQuartz = themeQuartz.withParams({
  accentColor: '#1753EB',
  backgroundColor: '#FFFFFF',
  borderColor: '#E5E7EB',
  borderRadius: '0px',
  checkboxBorderRadius: '2px',
  browserColorScheme: 'light',
  cellHorizontalPadding: '10px',
  cellHorizontalPaddingScale: undefined,
  cellTextColor: '#0E0F11',
  cellWidgetSpacing: '12px',
  checkboxBorderWidth: '1.5px',
  checkboxCheckedShapeColor: '#FFFFFF',
  checkboxIndeterminateBackgroundColor: '#FFFFFF',
  checkboxIndeterminateBorderColor: '#1753EB',
  checkboxIndeterminateShapeColor: '#1753EB',
  columnBorder: true,
  columnDropCellBackgroundColor: '#F9FAFB',
  columnDropCellBorder: '0.5px solid #DDE1E6',
  columnSelectIndentSize: '16px',
  fontFamily: ['Plus Jakarta Sans', 'Barlow'],
  fontSize: '13px',
  foregroundColor: '#636B75',
  headerBackgroundColor: '#EEF4FB',
  headerColumnBorder: true,
  headerRowBorder: '1px solid #CED7E0',
  headerColumnResizeHandleColor: '#0944BA00',
  headerCellMovingBackgroundColor: '#F9FAFB',
  headerFontSize: '12px',
  headerFontWeight: 600,
  headerHeight: '36px',
  headerTextColor: '#636B75',
  menuTextColor: '#636B75',
  menuBackgroundColor: '#FFFFFF',
  menuBorder: '0px',
  headerVerticalPaddingScale: 0.9,
  iconButtonHoverBackgroundColor: '#1343BF',
  iconButtonHoverColor: '#1344BF08',
  oddRowBackgroundColor: '#F9FAFB',
  rowHoverColor: '#E5E6EA29',
  rowVerticalPaddingScale: 1.1,
  selectCellBackgroundColor: '#A8ABB300',
  selectCellBorder: false,
  selectedRowBackgroundColor: '#1753EB00',
  sideBarBackgroundColor: '#FFFFFF',
  listItemHeight: '32px',
  inputBorder: '1px solid #dde1e6',
  inputBorderRadius: '4px',
  inputFocusBorder: '1px solid #7596eb',
  inputFocusShadow: '0 1px 3px #00000026',
  wrapperBorder: '0px'
})

LicenseManager.setLicenseKey(import.meta.env.VITE_TABLE_KEY)
ModuleRegistry.registerModules([
  MenuModule,
  ClipboardModule,
  SetFilterModule,
  CsvExportModule,
  MultiFilterModule,
  RowGroupingModule,
  ExcelExportModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  ClientSideRowModelModule
])

const props = defineProps({
  tableGridType: {
    type: String as () => TableGridType,
    required: true
  },
  defaultColDef: {
    type: Object as () => ColDef,
    default: () => ({
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
      filter: true,
      suppressFiltersToolPanel: true,
      cellClassRules: {
        'is-number-cell': (params: CellClassParams) => params?.column?.getColDef()?.cellDataType === 'number' // 数字列右对齐
      },
      headerClass: (params: HeaderClassParams) => {
        return params?.column?.getColDef()?.cellDataType === 'number' ? 'is-number-header-cell' : ''
      },
      enableRowGroup: true // 启用行分组拖拽
    })
  },
  sideBar: {
    type: Object as () => SideBarDef,
    default: () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true // 隐藏聚合设置选项
          }
        }
      ],
      defaultToolPanel: null // 隐藏不展开侧边栏
    })
  },
  autoGroupColumnDef: {
    type: Object as () => ColDef,
    default: () => ({
      minWidth: 188,
      filter: true,
      suppressFiltersToolPanel: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: GroupCountCellRenderer
      }
    })
  }
})

const route = useRoute()
const { locale } = useI18n()
const globalStore = useGlobalStore()
const emit = defineEmits(['onFetchTableList', 'onCellClicked'])

const gridApi = shallowRef<GridApi | null>(null)
const rowData = ref<any>(null)
const initialState = ref<GridState | undefined>(undefined)
const gridKey = ref(0) // 用于强制重新创建表格

// ag-grid基础配置
const gridOptions: any = reactive({
  tooltipShowDelay: 500,
  animateRows: true,
  debounceVerticalScrollbar: true,
  suppressPropertyNamesCheck: true,
  groupAllowUnbalanced: true, // 允许不平衡的分组
  groupTotalRow: true,
  localeText: locale.value === 'en' ? AG_GRID_LOCALE_EN : AG_GRID_LOCALE_CN,
  theme: elvThemeQuartz,
  rowGroupPanelShow: 'always', // 始终显示分组面板
  suppressServerSideFullWidthLoadingRow: true, // 骨架加载行
  maxBlocksInCache: 10, // 缓存中最多有多少块。如果达到最大值，块将从缓存中删除
  // maxConcurrentDatasourceRequests: 1, // 有多少请求同时命中服务器。如果达到最大值，请求将排队
  // cacheBlockSize: 100, // 缓存中每个块有多少行，即一次从服务器返回多少行
  blockLoadDebounceMillis: 20, // 加载块之前等待多少毫秒。在无限滚动和滚动许多无限块时很有用，因为它可以防止块加载直到滚动结束。
  serverSideInitialRowCount: 0, // 初始化从第几行开始显示
  overlayNoRowsTemplate: `<div class="elv-result">
    <img src="${noDataImg}" style="width: 40px; height: 40px;margin-bottom:16px" />
    <div class="elv-result-description">
      No data matches this filter
    </div>
  </div>`
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

/**
 * @description: 重置表格状态
 */
const resetGridState = () => {
  globalStore.editGridTableState(entityId.value, props.tableGridType, null)
  initialState.value = undefined
  gridApi.value?.destroy()
  gridKey.value += 1
}

/**
 * @description: 单元格点击事件
 * @param {CellClickedEvent} cell
 */
const onCellClicked = (cell: CellClickedEvent) => {
  console.log(cell, 'cell')
  emit('onCellClicked', cell)
}
/**
 * @description: ag-gridAPI初始化
 * @param {DetailGridInfo} params
 */
const onGridReady = async (params: DetailGridInfo) => {
  // 获取gridApi
  gridApi.value = params?.api as GridApi
  emit('onFetchTableList')
}

/**
 * @description: 获取行ID
 */
// eslint-disable-next-line no-unused-vars
const getRowId = () => {
  const rowId = `${Math.floor(Math.random() * 900)}`
  return uniqueId(rowId)
}

/**
 * @description: ag-grid销毁前事件
 * @param {StateUpdatedEvent} params
 */
const onGridPreDestroyed = (params: StateUpdatedEvent) => {
  console.log('Grid state on destroy (can be persisted)', params.state)
  globalStore.editGridTableState(entityId.value, props.tableGridType, params.state)
}

/**
 * @description: ag-grid状态更新事件
 * @param {StateUpdatedEvent} params
 */
const onStateUpdated = (params: StateUpdatedEvent) => {
  console.log('State updated', params.state)
  globalStore.editGridTableState(entityId.value, props.tableGridType, params.state)
}

/**
 * @description: 重置表格数据
 */
const resetTableList = () => {
  const firstDisplayedRowIndex = gridApi.value?.getFirstDisplayedRowIndex()
  if (firstDisplayedRowIndex !== undefined) {
    gridApi.value?.ensureIndexVisible(firstDisplayedRowIndex || 0, 'top')
  }
  rowData.value = null
  gridApi.value?.setGridOption('rowData', rowData.value)
  emit('onFetchTableList')
}

defineExpose({ gridApi, resetGridState, resetTableList })

watch(
  () => locale.value,
  () => {
    gridOptions.localeText = locale.value === 'en' ? AG_GRID_LOCALE_EN : AG_GRID_LOCALE_CN
    gridApi.value?.refreshHeader()
    gridApi.value?.destroy()
    gridKey.value += 1
  }
)

onBeforeMount(() => {
  const gridTableState = globalStore.gridTableStateList.find(
    (item) => item.entityId === entityId.value && item.tableType === props.tableGridType
  )
  if (!isEmpty(gridTableState)) {
    initialState.value = gridTableState.gridState
  }
})

onBeforeUnmount(() => {
  gridApi.value?.destroy()
})
</script>

<style lang="scss" scoped>
.elv-grid-theme-quartz-table {
  --ag-wrapper-border-radius: 0px;

  :deep(.ag-root-wrapper) {
    .ag-menu {
      border-radius: 6px;
      box-shadow:
        0px 2px 6px 0px rgba(0, 0, 0, 0.05),
        0px 0px 1px 0px rgba(0, 0, 0, 0.3);

      .ag-menu-option {
        height: 32px;

        &.ag-menu-option-active {
          background-color: #f9fafb;
        }
      }

      .ag-menu-option-icon {
        padding: 9px 0 9px 8px;

        .ag-icon {
          width: 14px;
          height: 14px;

          &::before {
            width: 14px;
            height: 14px;
            background-color: #838d95;
          }
        }
      }

      .ag-menu-option-text {
        padding: 0 10px;
        color: #1e2024;
        font-size: 12px;
        font-weight: 400;
      }

      .ag-menu-option-popup-pointer {
        padding: 8px;
        padding-left: 0px;
      }

      .ag-menu-separator {
        height: 1px;
      }
    }

    .ag-icon.ag-icon-grip {
      width: 14px;
      height: 14px;

      &::before {
        width: 14px;
        height: 14px;
        mask-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNSIgPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC4zNzUgMy4xMjVINi4xMjVWNC44NzVINC4zNzVWMy4xMjVaTTcuODc1IDMuMTI1SDkuNjI1VjQuODc1SDcuODc1VjMuMTI1Wk02LjEyNSA2LjYyNUg0LjM3NVY4LjM3NUg2LjEyNVY2LjYyNVpNNy44NzUgNi42MjVIOS42MjVWOC4zNzVINy44NzVWNi42MjVaTTYuMTI1IDEwLjEyNUg0LjM3NVYxMS44NzVINi4xMjVWMTAuMTI1Wk03Ljg3NSAxMC4xMjVIOS42MjVWMTEuODc1SDcuODc1VjEwLjEyNVoiIGZpbGw9IiM4MzhEOTUiLz4KPC9zdmc+');
      }
    }

    .ag-icon.ag-icon-cancel {
      width: 14px;
      height: 14px;

      &::before {
        width: 14px;
        height: 14px;
        mask-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNSIgPgogIDxwYXRoIGQ9Ik0xMC41IDQuNjEyNUw5Ljg4NzUgNEw3IDYuODg3NUw0LjExMjUgNEwzLjUgNC42MTI1TDYuMzg3NSA3LjVMMy41IDEwLjM4NzVMNC4xMTI1IDExTDcgOC4xMTI1TDkuODg3NSAxMUwxMC41IDEwLjM4NzVMNy42MTI1IDcuNUwxMC41IDQuNjEyNVoiIGZpbGw9IiNBQUFGQjYiLz4KPC9zdmc+');
      }
    }

    .ag-column-drop-wrapper {
      .ag-column-drop.ag-column-drop-horizontal {
        padding: 10px 20px;
        height: 45px;
        background-color: #fff;
        border-bottom: 1px solid #ced7e0;
      }

      .ag-column-drop-title-bar.ag-column-drop-horizontal-title-bar {
        width: 57px;

        .ag-icon.ag-icon-group {
          &::before {
            content: 'Group by:';
            display: block;
            mask-image: none;
            background-color: transparent;
            color: #838d95;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .ag-column-drop-horizontal-list {
        gap: 4px;

        .ag-column-drop-cell.ag-column-drop-horizontal-cell {
          padding: 5px 6px;
          border-radius: 40px;
          height: 25px;
          gap: 4px;

          .ag-column-drop-cell-text.ag-column-drop-horizontal-cell-text {
            color: #636b75;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transform: translateY(-1px);
          }

          .ag-icon.ag-icon-cancel {
            margin-left: 2px;
          }
        }

        .ag-column-drop-horizontal-cell-separator {
          &::before {
            background-color: #838d95;
          }
        }
      }
    }

    .ag-header-icon.ag-filter-active {
      background-color: transparent;
      box-shadow: none;

      &::after {
        background-color: #5e85eb;
      }
    }

    .ag-input-wrapper {
      .ag-text-field-input {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          border: 1px solid #7596eb;
          box-shadow: 0 1px 3px #00000026;
        }
      }

      &.ag-checkbox-input-wrapper {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          border-color: #1753eb;

          .ag-checkbox-input {
            cursor: pointer;
          }
        }
      }
    }

    .ag-row {
      .ag-cell:not(.ag-cell-inline-editing) {
        padding: 0 10px;
      }

      &.ag-row-group {
        .ag-group-contracted,
        .ag-group-expanded {
          margin-right: 4px;
        }
      }
    }

    .is-number-header-cell {
      padding: 0 10px;

      .ag-header-cell-label {
        justify-content: flex-end; /* 右对齐 */
        margin-right: 8px;
      }
    }

    .is-number-cell {
      text-align: right;
      color: #0e0f11;
      font-family: 'Barlow';
      padding: 0 10px;
    }

    .ag-row-group .ag-cell-wrapper.ag-cell-expandable {
      .ag-group-child-count {
        color: #838d95;
        font-family: 'Barlow';
        font-weight: 500;
        margin-left: 10px;
      }
    }

    .ag-side-bar {
      .ag-column-select-header {
        height: 48px;
      }

      .ag-column-select-column {
        gap: 4px;

        .ag-icon.ag-icon-grip {
          width: 18px;
          height: 18px;

          &::before {
            width: 18px;
            height: 18px;
          }
        }
      }

      .ag-column-drop.ag-column-drop-vertical {
        .ag-column-drop-cell {
          height: 25px;
          padding: 6px 5px;
          gap: 4px;
        }
      }
    }
  }
}
</style>
